<template>
  <div class="app">
    <Menu :list="menuList"/>

    <div class="px-5 sm:px-52 mx-auto my-20">
      <div style="font-size: 20px">
        <div>
          <h2 class="font-extrabold color-purple text-3xl RobotoMedium" >Educación Cooperativa</h2>
          <div class="liner-yellow"></div>
        </div>
      </div>
      
      <div class="mt-10">
        <h3 class="font-extrabold color-purple text-2xl RobotoMedium">Cooperativa</h3>
      </div>

      <div class="mt-10">
        <div>
          <h4 class="font-extrabold color-grey text-2xl RobotoMedium">¿Qué es una cooperativa?</h4>
          <p class="color-grey text-info">Es una asociación autónoma de personas que se han unido de forma voluntaria para satisfacer sus necesidades y aspiraciones económicas, sociales y culturales en común, mediante una empresa de propiedad conjunta y gestión democrática.</p>
        </div>
      </div>

       <div class="relative flex py-5 items-center">
        <div class="flex-grow border-t border-gray-400"></div>
      </div>

      <div class="mt-10">
        <h3 class="font-extrabold color-purple text-2xl RobotoMedium">Valores cooperativos</h3>
      </div>

      <div class="mt-10">
        <div>
          <h4 class="font-extrabold color-grey text-2xl RobotoMedium">Valores empresariales</h4>
          <br>
          <div style="display: flex; margin-top: -2px; font-size: 18px">
            <p class="color-grey text-info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-check"
                viewBox="0 0 16 16"
              >
                <path
                  d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                />
              </svg>
            </p>
            <p class="color-grey text-info">Valores empresariales</p>
          </div>
          <div style="display: flex; margin-top: -2px; font-size: 18px">
            <p class="color-grey text-info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-check"
                viewBox="0 0 16 16"
              >
                <path
                  d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                />
              </svg>
            </p>
            <p class="color-grey text-info">Ayuda mutua</p>
          </div>
          <div style="display: flex; margin-top: -2px; font-size: 18px">
            <p class="color-grey text-info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-check"
                viewBox="0 0 16 16"
              >
                <path
                  d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                />
              </svg>
            </p>
            <p class="color-grey text-info">Autorresponsabilidad</p>
          </div>
          <div style="display: flex; margin-top: -2px; font-size: 18px">
            <p class="color-grey text-info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-check"
                viewBox="0 0 16 16"
              >
                <path
                  d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                />
              </svg>
            </p>
            <p class="color-grey text-info">Democracia</p>
          </div>
          <div style="display: flex; margin-top: -2px; font-size: 18px">
            <p class="color-grey text-info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-check"
                viewBox="0 0 16 16"
              >
                <path
                  d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                />
              </svg>
            </p>
            <p class="color-grey text-info">Igualdad</p>
          </div>
          <div style="display: flex; margin-top: -2px; font-size: 18px">
            <p class="color-grey text-info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-check"
                viewBox="0 0 16 16"
              >
                <path
                  d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                />
              </svg>
            </p>
            <p class="color-grey text-info">Equidad</p>
          </div>
          <div style="display: flex; margin-top: -2px; font-size: 18px">
            <p class="color-grey text-info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-check"
                viewBox="0 0 16 16"
              >
                <path
                  d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                />
              </svg>
            </p>
            <p class="color-grey text-info">Solidaridad</p>
          </div>
        </div>
      </div>

      <div class="mt-10">
        <div>
          <h4 class="font-extrabold color-grey text-2xl RobotoMedium">Valores éticos</h4>
          <br>
          <div style="display: flex; margin-top: -2px; font-size: 18px">
            <p class="color-grey text-info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-check"
                viewBox="0 0 16 16"
              >
                <path
                  d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                />
              </svg>
            </p>
            <p class="color-grey text-info">Honestidad</p>
          </div>
          <div style="display: flex; margin-top: -2px; font-size: 18px">
            <p class="color-grey text-info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-check"
                viewBox="0 0 16 16"
              >
                <path
                  d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                />
              </svg>
            </p>
            <p class="color-grey text-info">Transparencia</p>
          </div>
          <div style="display: flex; margin-top: -2px; font-size: 18px">
            <p class="color-grey text-info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-check"
                viewBox="0 0 16 16"
              >
                <path
                  d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                />
              </svg>
            </p>
            <p class="color-grey text-info">Liderazgo</p>
          </div>
          <div style="display: flex; margin-top: -2px; font-size: 18px">
            <p class="color-grey text-info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-check"
                viewBox="0 0 16 16"
              >
                <path
                  d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                />
              </svg>
            </p>
            <p class="color-grey text-info">Responsabilidad</p>
          </div>
        </div>
      </div>

      <div class="mt-10">
        <div>
          <h4 class="font-extrabold color-grey text-2xl RobotoMedium">¿Cuáles son los principios cooperativos?</h4>
          <br>
          <div style="display: flex; margin-top: -2px; font-size: 18px">
            <p class="color-grey text-info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-check"
                viewBox="0 0 16 16"
              >
                <path
                  d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                />
              </svg>
            </p>
            <p class="color-grey text-info">Adhesión libre y voluntaria</p>
          </div>
          <div style="display: flex; margin-top: -2px; font-size: 18px">
            <p class="color-grey text-info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-check"
                viewBox="0 0 16 16"
              >
                <path
                  d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                />
              </svg>
            </p>
            <p class="color-grey text-info">Control democrático de los asociados</p>
          </div>
          <div style="display: flex; margin-top: -2px; font-size: 18px">
            <p class="color-grey text-info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-check"
                viewBox="0 0 16 16"
              >
                <path
                  d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                />
              </svg>
            </p>
            <p class="color-grey text-info">Participación económica</p>
          </div>
          <div style="display: flex; margin-top: -2px; font-size: 18px">
            <p class="color-grey text-info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-check"
                viewBox="0 0 16 16"
              >
                <path
                  d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                />
              </svg>
            </p>
            <p class="color-grey text-info">Autonomía e independencia</p>
          </div>
          <div style="display: flex; margin-top: -2px; font-size: 18px">
            <p class="color-grey text-info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-check"
                viewBox="0 0 16 16"
              >
                <path
                  d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                />
              </svg>
            </p>
            <p class="color-grey text-info">Educación, formación e información</p>
          </div>
          <div style="display: flex; margin-top: -2px; font-size: 18px">
            <p class="color-grey text-info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-check"
                viewBox="0 0 16 16"
              >
                <path
                  d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                />
              </svg>
            </p>
            <p class="color-grey text-info">Compromiso con la comunidad</p>
          </div>
        </div>

        <div class="mt-10">
          <h3 class="color-grey text-info">Los principios cooperativos, son la columna vertebral de la gestión empresarial cooperativa y de su posterior impacto en la sociedad. Son pautas mediante las cuales se ponen en práctica los valores cooperativos. Son, en esencia, la guía inmediata y permanente que fortalece la acción y desarrollo de toda organización cooperativa.</h3>
          <h3 class="color-grey text-info">A través de los principios se entiende cómo la cooperación no sólo es una herramienta valiosa, sino vital para la generación de rentabilidad social, bienestar, prosperidad económica y calidad de vida para todos los individuos.</h3>
        </div>
      </div>
    </div>

    <a name="footer"></a>
    <Footer/>
  </div>
</template>

<script>
import Footer from '../components/Footer3.vue';
import Menu from '../components/Menu.vue';
import ListTexts from '../components/ListaTextos.vue';

import menuOptions  from "@/helpers/menu.js";
import { ref, onBeforeMount } from 'vue';

export default {
  setup() {
    let menuList = ref([]);

    onBeforeMount(async () => {
        await getMenuOptionsList();
    });

    const getMenuOptionsList = async () => {
      await menuOptions.getMenuOptions().then(resp => {
          menuList.value = resp.data.FONCABSA;
      }).catch(err => {
          console.log("🚀 ~ file: Home.vue:223 ~ getMenuOptions ~ err", err);
      });
    };

    return {
      menuList,
      savings: [
        `<p>¿Qué es una cooperativa?</p> `,
        `<p class="mb-5"> Es una asociación autónoma de personas que se han unido de forma voluntaria para satisfacer sus necesidades y aspiraciones económicas, sociales y culturales en común, mediante una empresa de propiedad conjunta y gestión democrática.</p>`,
      ],

      corporateValues: [
            `<p class="my-5 color-purple"> Valores empresariales </p>`,
            `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check"viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" /></svg>  Valores empresariales`,
            `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check"viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" /></svg> Ayuda mutua`,
            `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check"viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" /></svg> Autorresponsabilidad`,
            `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check"viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" /></svg> Democracia`,
            `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check"viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" /></svg> Igualdad`,
            `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check"viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" /></svg> Equidad`,
            `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check"viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" /></svg> Solidaridad`
      ],
      ethicalValue: [
            `<p class="my-5 color-purple"> Valores éticos </p>`,
            `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check"viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" /></svg> Honestidad`,
            `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check"viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" /></svg> Transparencia`,
            `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check"viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" /></svg> Liderazgo`,
            `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check"viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" /></svg> Responsabilidad`
      ],
      whatValues: [
            `<p class="my-5 color-purple">¿Cuáles son los principios cooperativos?</p>`,
            `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check"viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" /></svg> Adhesión libre y voluntaria`,
            `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check"viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" /></svg> Control democrático de los asociados`,
            `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check"viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" /></svg> Participación económica`,
            `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check"viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" /></svg> Autonomía e independencia`,
            `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check"viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" /></svg> Educación, formación e información`,
            `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-check"viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" /></svg> Compromiso con la comunidad`,
      ]

    };
  },

  name: 'EducacionCooperativa',
  components: {
    Menu,
    ListTexts,
    Footer
  },
 
  methods:{
 
  }
}
</script>

<style scoped>
hr {
  margin-top: 0px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1.8px solid #e5e4e4 !important;
}
.text-information {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 22px;
  margin-bottom: 70px;
  margin-top: 70px;
}

.margin-text-information {
  margin: 30px;
}

.text-information {
  font-size: 16px;
}
.text-info{
  font-family: "RobotoRegular";
}
.color-purple{
  color: #810042!important;
}
.color-gray {
  color: #726659;
}

  .liner-yellow{
   width: 100px;
    background: #f8c603;
    height: 5px; 
  }
</style>
